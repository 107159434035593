<template>
  <div>
    <!--      头部      -->
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-input v-model="Keyword" type="text" size="small" placeholder="角色名称" @change="Search" clearable=""></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                <el-button type="primary" size="small" @click="addRole()" plain>新增</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- <el-tabs v-model="activeTabs" @tab-click="handleClick" >
                    <el-tab-pane v-for="(tabs,index) in CategoryTab" :key="tabs.id" :label="tabs.name" :name="tabs.id"></el-tab-pane>
                </el-tabs> -->
        <template v-if="tableData.length > 0">
          <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
            <el-table-column prop="roleName" label="角色名称"> </el-table-column>
            <el-table-column prop="alias" label="别名" align="center"> </el-table-column>
            <el-table-column prop="roleKey" label="角色标志" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.roleKey == 'Manager'">管理员</p>
                <p v-if="scope.row.roleKey == 'Service' && ClientId == YunClient">药房</p>
                <p v-if="scope.row.roleKey == 'Service' && ClientId != YunClient">客服</p>
              </template>
            </el-table-column>
            <el-table-column prop="roleDescription" label="角色描述" align="center"> </el-table-column>
            <!-- <el-table-column prop="roleStatu" :formatter="roleStatus" label="状态" align="center"> </el-table-column> -->
            <!--<el-table-column
                                prop="date"
                                label="添加时间">
                        </el-table-column>-->
            <el-table-column fixed="right" label="操作" width="300" align="center">
              <template slot-scope="scope">
                <span class="info optionBtn" @click="editTableData(scope.$index)">编辑</span>
                <!-- <el-button size="mini" type="primary" @click="rbacSetting(scope.$index)" plain>权限设置</el-button> -->
                <span class="success optionBtn" @click="menuSetting(scope.$index)">菜单设置 </span>
                <!-- <el-button size="mini" type="warning" @click="catalogueSetting(scope.$index)" plain>目录设置</el-button> -->
                <span class="danger optionBtn" @click="deleteList(scope.$index)">删除 </span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-empty v-else :image-size="200"></el-empty>
      </div>
    </div>
    <!--    Dialog 新增    -->
    <el-dialog title="新增" :visible.sync="addDialog">
      <el-form :model="roleForm" :rules="rules" ref="addForm">
        <el-form-item label="角色名称" :label-width="formLabelWidth" prop="roleName">
          <el-input v-model="roleForm.roleName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="别名" :label-width="formLabelWidth">
          <el-input v-model="roleForm.alias" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" :label-width="formLabelWidth">
          <el-input v-model="roleForm.roleDescription" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色标志" :label-width="formLabelWidth" prop="roleKey">
          <el-select v-model="roleForm.roleKey" placeholder="请选择角色标志">
            <!-- <el-option label="超级管理员" value="SuperManager"></el-option> -->
            <el-option label="管理员" value="Manager"></el-option>
            <el-option label="药房" value="Service" v-if="ClientId == YunClient"></el-option>
            <el-option label="客服" value="Service" v-else></el-option>
            <!-- <el-option label="用户" value="User"></el-option> -->
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel('addForm')">取 消</el-button>
        <el-button type="primary" @click="addRoleBtn('addForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!--    Dialog 修改    -->
    <el-dialog title="修改" :visible.sync="editDialog">
      <el-form :model="roleForm" :rules="rules" ref="editForm">
        <el-form-item label="角色名称" :label-width="formLabelWidth" prop="roleName">
          <el-input v-model="roleForm.roleName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="别名" :label-width="formLabelWidth">
          <el-input v-model="roleForm.alias" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" :label-width="formLabelWidth">
          <el-input v-model="roleForm.roleDescription" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色标志" :label-width="formLabelWidth" prop="roleKey">
          <el-select v-model="roleForm.roleKey" placeholder="请选择角色标志" disabled>
            <!-- <el-option label="超级管理员" value="SuperManager"></el-option> -->
            <el-option label="管理员" value="Manager"></el-option>
            <el-option label="客服" value="Service" v-if="ClientId != YunClient"></el-option>
            <!-- <el-option label="用户" value="User"></el-option> -->
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="状态" :label-width="formLabelWidth">
          <el-radio-group v-model="roleForm.roleStatu" size="mini">
            <el-radio-button label="1">启用</el-radio-button>
            <el-radio-button label="-1">禁用</el-radio-button>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel('editForm')">取 消</el-button>
        <el-button type="primary" @click="editRoleBtn('editForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!--    Dialog 菜单管理    -->
    <el-dialog title="角色菜单管理" :visible.sync="menuDialog" @close="closemenuDialog">
      <el-scrollbar style="height: 400px">
        <p v-if="menuTree[0].client">{{ menuTree[0].client.name }}</p>
        <el-tree
          @check="
            (click, checked) => {
              handleCheckChange(click, checked)
            }
          "
          ref="tree_menu"
          :data="menuTree"
          show-checkbox
          node-key="id"
          :default-checked-keys="selectedmenuTree"
          :props="{ children: 'children', label: 'name' }"
        >
        </el-tree>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="menuDialog = false">取 消</el-button>
        <el-button type="primary" @click="menuBtn()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Role } from '../../../components/HospitalDomain/Role.js'
// import { Subsystem } from '../../components/AuthorizationDomain/Subsystem.js'
import { Menu } from '../../../components/HospitalDomain/Menu'
import bread from '../../../components/common/breadcrumb'
export default {
  name: 'Role',
  components: {
    bread,
  },
  data() {
    var menu = new Menu(this.TokenClient, this.Services.Authorization)
    return {
      MenuDomain: menu,
      activeTabs: '1',
      Keyword: '',
      CategoryTab: [],
      searchForm: {},
      tableData: [],
      roleForm: {
        roleId: '',
        roleKey: 'Manager',
        roleName: '',
        roleType: 0,
        alias: '',
        roleDescription: '',
        productId: JSON.parse(sessionStorage.getItem('userInfo')).productId,
        roleStatu: 1,
      },
      rules: {
        roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        roleKey: [{ required: true, message: '请选择角色标志', trigger: 'blur' }],
        roleDescription: [{ required: true, message: '请输入角色描述', trigger: 'blur' }],
      },
      formLabelWidth: '120px',
      addDialog: false,
      editDialog: false,
      rbacDialog: false,
      menuDialog: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      rabcCheckedKey: [],
      checkList: [],
      // menuTree: [
      //   {
      //     client: {
      //       name: '',
      //     },
      //   },
      // ],
      menuTree: JSON.parse(sessionStorage.getItem('AllMenus')),
      selectedmenuTree: [], // 默认勾选的节点的 key 的数组
      menuArr: [],
      menuForm: {
        roleId: 0,
        menuIdArr: [],
      },
    }
  },
  mounted() {
    this.getRole()
    // this.getMenuClientCurrent()
    // this.GetCategoryAll();
  },
  methods: {
    handleClick(row) {
      console.log(row)
    },
    getRoleDomain: function () {
      return new Role(this.TokenClient, this.Services.Authorization)
    },
    getSubsystem: function () {
      return new Subsystem(this.TokenClient, this.Services.Authorization)
    },
    SearchInputKeydown(event) {
      if (event.keyCode == 13) {
        this.getRole()
      }
    },
    Search(event) {
      this.getRole()
    },
    handleClick() {
      this.getRole()
    },
    //查询所有角色
    getRole: function () {
      const that = this
      var role = this.getRoleDomain()
      that.tableData = []
      role.CateRoles(
        that.Keyword,
        false,
        true,
        function (obj) {
          that.tableData = obj.data
          // console.log(obj.data)
        },
        function (err) {
          console.log(err)
          that.tableData = []
        }
      )
    },
    //角色类型
    roleCategoryId(row) {
      return row.category.name
    },
    //状态 对应状态码
    roleStatus(row) {
      if (row.roleStatu == '1') {
        return '启用'
      } else if (row.roleStatu == '-1') {
        return '禁用'
      }
    },
    //新增
    addRole() {
      this.roleForm = {
        roleKey: 'Manager',
        roleName: '',
        alias: '',
        roleDescription: '',
        productId: JSON.parse(sessionStorage.getItem('userInfo')).productId,
        roleStatu: 1,
        roleType: 0,
      }
      //   设置点击按钮之后进行显示对话框
      this.addDialog = true
    },
    addRoleBtn() {
      const that = this
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          var role = this.getRoleDomain()
          role.Add(
            parseInt(this.roleForm.productId),
            this.roleForm.roleKey,
            this.roleForm.roleName,
            this.roleForm.alias,
            this.roleForm.roleDescription,
            this.roleForm.roleType,
            function (data) {
              that.getRole()
              that.addDialog = false
              that.$message({
                type: 'success',
                message: '操作成功!',
              })
            },
            function (err) {
              var jsonObj = JSON.parse(err.msg)
              if (jsonObj.code == '400') {
                that.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    cancel(formName) {
      // 取消的时候直接设置对话框不可见即可
      this.editDialog = false
      this.addDialog = false
      this.rbacDialog = false
      this.menuDialog = false
      this.$refs[formName].resetFields()
    },
    //编辑
    editTableData(index) {
      console.log(this.tableData[index])
      this.roleForm.id = this.tableData[index].id
      this.roleForm.roleName = this.tableData[index].roleName
      this.roleForm.alias = this.tableData[index].alias
      this.roleForm.roleKey = this.tableData[index].roleKey
      this.roleForm.roleDescription = this.tableData[index].roleDescription
      this.roleForm.roleStatu = this.tableData[index].roleStatu.toString()
      this.roleForm.roleType = 0
      this.roleForm.productId = this.tableData[index].productId
      this.editDialog = true
      console.log(this.roleForm)
    },
    editRoleBtn() {
      const that = this
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          var role = this.getRoleDomain()
          role.Edit(
            this.roleForm.id,
            parseInt(this.roleForm.productId),
            this.roleForm.roleKey,
            this.roleForm.roleName,
            this.roleForm.alias,
            this.roleForm.roleDescription,
            parseInt(this.roleForm.roleStatu),
            this.roleForm.roleType,
            function (data) {
              that.getRole()
              that.editDialog = false
              that.$message({
                type: 'success',
                message: '操作成功!',
              })
            },
            function (err) {
              var jsonObj = JSON.parse(err.msg)
              if (jsonObj.code == '400') {
                that.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    rbacSetting(index) {
      const that = this
      var role = this.getRoleDomain()
      this.roleForm.id = this.tableData[index].id
      role.Models(
        this.roleForm.id,
        function (data) {
          that.rabcCheckedKey = [] //data.data;

          //取出最后一级的Model数据
          var childrenModels = []
          that.data.forEach(function (subsystem) {
            subsystem.children.forEach(function (model) {
              model.children.forEach(function (children) {
                childrenModels.push(children.id)
              })
            })
          })
          console.log(childrenModels)
          //取出已设置的Model数据
          var checkModels = []
          for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
            checkModels.push('model-' + data.data[dataIndex])
          }
          //取交集
          that.rabcCheckedKey = childrenModels.filter(function (val) {
            return checkModels.indexOf(val) > -1
          })

          that.$refs.rabcTree.setCheckedKeys(that.rabcCheckedKey)
        },
        function () {}
      )
      this.rbacDialog = true
    },
    rbacBtn(index) {
      const that = this
      const menuIds = this.$refs.rabcTree.getCheckedKeys()
      const halfMenuIds = this.$refs.rabcTree.getHalfCheckedKeys()
      var checkSubsystems = [],
        checkModels = []
      var KeyHandle = function (key) {
        if (key[0] == 'subsystem') checkSubsystems.push(parseInt(key[1]))
        else if (key[0] == 'model') checkModels.push(parseInt(key[1]))
      }
      for (var checkIndex = 0; checkIndex < menuIds.length; checkIndex++) {
        KeyHandle((menuIds[checkIndex] || '').split('-'))
      }
      for (var halfCheckIndex = 0; halfCheckIndex < halfMenuIds.length; halfCheckIndex++) {
        KeyHandle((halfMenuIds[halfCheckIndex] || '').split('-'))
      }
      var role = this.getRoleDomain()
      role.SetSubsystem(
        that.roleForm.id,
        checkSubsystems,
        function (data) {
          role.SetModel(
            that.roleForm.id,
            checkModels,
            function (data) {},
            function (err) {
              console.log(err)
            }
          )
          that.rbacDialog = false
          that.$message({
            type: 'success',
            message: '操作成功!',
          })
        },
        function (err) {
          console.log(err)
        }
      )
    },
    closerbacDialog() {
      this.$refs.rabcTree.setCheckedKeys([])
    },
    //删除
    deleteList(index) {
      this.$confirm('确定删除该角色?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const that = this
          var role = this.getRoleDomain()
          this.roleForm.id = this.tableData[index].id
          role.Remove(
            this.roleForm.id,
            function () {
              that.$message({
                type: 'success',
                message: '操作成功!',
              })
              /*this.tableData.splice(index, 1);*/
              that.getRole()
            },
            function (err) {}
          )
        })
        .catch(() => {})
    },
    getMenuClientCurrent() {
      var _this = this
      _this.MenuDomain.MenuClientCurrent(
        function (data) {
          _this.menuTree = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    menuSetting(index) {
      var _this = this
      _this.menuDialog = true
      _this.selectedmenuTree = []
      _this.menuForm.roleId = _this.tableData[index].id
      _this.MenuDomain.GetRoleMenus(
        _this.menuForm.roleId,
        function (data) {
          data.data.forEach((i, n) => {
            let treeArr = _this.$refs.tree_menu
            let node = treeArr.getNode(i)
            if (node && node.isLeaf) {
              treeArr.setChecked(node, true)
            }
          })
        },
        function (err) {
          if (err.status == '404') {
            _this.selectedmenuTree = []
          }
          console.log(err)
          return
        }
      )
    },
    closemenuDialog() {
      this.selectedmenuTree = []
      let treeArr = this.$refs.tree_menu
      let menu_list = treeArr.setCheckedKeys([])
    },
    handleCheckChange(click, checked) {
      let treeArr = this.$refs.tree_menu
      console.log(treeArr)
      this.menuArr = []
      // treeArr.forEach((e) => {
      // let menu_list = e.getCheckedNodes();
      let menu_list = treeArr.getCheckedKeys()
      // console.log(menu_list);
      let menus_list = treeArr.getHalfCheckedKeys()
      // console.log(menus_list);
      menu_list.forEach((e) => {
        this.menuArr.push(e)
      })
      menus_list.forEach((e) => {
        this.menuArr.push(e)
      })
      // });
      console.log('newArr', this.menuArr)
      this.$emit('menu_change', this.menuArr)
    },
    menuBtn() {
      var _this = this
      _this.menuForm.menuIdArr = _this.menuArr
      console.log(_this.menuForm)
      _this.MenuDomain.roleMenu(
        _this.menuForm,
        function (data) {
          _this.$message({
            type: 'success',
            message: '操作成功!',
          })
          _this.menuDialog = false
        },
        function (err) {
          console.log(err)
        }
      )
    },
    catalogueSetting(index) {
      console.log(this.tableData[index].id)
      // 跳转至订单列表页面传参
      this.$router.push({
        path: '/system/role/Catalogue',
        query: {
          id: this.tableData[index].id,
        },
      }) // 带参跳转
    },
  },
}
</script>

<style scoped>
/*content*/

.iq-search-bar {
  display: inline-block;
  margin-right: 20px;
}

.iq-search-bar .searchbox {
  width: 480px;
  position: relative;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #ceebee;
  line-height: 1.8;
}
</style>
<style>
.el-scrollbar__wrap {
  overflow: auto;
}
</style>
