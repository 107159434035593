let Menu = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * 查询菜单分组
     */
    this.MenuClientCurrent = function ( success, error) {
        var url = ServiceBaseAddress + '/api/Menu/Client/Current';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 查询菜单分组
     */
     this.AllGroup = function ( success, error) {
        var url = ServiceBaseAddress + '/api/Menu/Category';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 查询分组下菜单列表
     * @param group
     * @param success
     * @param error
     */
    this.GroupMenu = function (group, success, error) {
        var url = ServiceBaseAddress + '/api/Menu/FindByGroup/'+ group;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 查询菜单详情
     * @param menuId
     * @param success
     * @param error
     * @constructor
     */
    this.MenuDetail = function (menuId, success, error) {
        var url = ServiceBaseAddress + '/api/Menu/'+ menuId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 新增菜单
     * @param menuInfo
     * @param success
     * @param error
     * @constructor
     */
    this.Add = function (menuInfo, success, error) {
        var url = ServiceBaseAddress + '/api/Menu';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, menuInfo,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }


    /**
     * 编辑菜单
     * @param menuInfo
     * @param success
     * @param error
     * @constructor
     */
    this.Edit = function (menuInfo, success, error) {
        var url = ServiceBaseAddress + '/api/Menu';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, menuInfo,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 删除菜单
     * @param {any} menuId
     * @param {any} success
     * @param {any} error
     */
    this.Remove = function (menuId, success, error) {
        var url = ServiceBaseAddress + '/api/Menu/?menuId=' + menuId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 角色菜单授权
     * @param roleArr
     * @param success
     * @param error
     */
    this.roleMenu = function (roleArr, success, error) {
        var url = ServiceBaseAddress + '/api/Role/Menus';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, roleArr,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取指定角色下菜单ID集合
     * @param roleId
     * @param success
     * @param error
     * @constructor
     */
    this.GetRoleMenus = function (roleId, success, error) {
        var url = ServiceBaseAddress + '/api/Role/Menus/'+ roleId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 查询菜单可用模块
     * @param menuId
     * @param success
     * @param error
     * @constructor
     */
     this.GetMenus = function (menuId,success, error) {
        var url = ServiceBaseAddress + '/api/Menu/'+menuId+'/Model';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 设置菜单权限
     * @param {any} menuId
     * @param {any} subsystemIds 子系统的id数组,[1,2,3,4]
     * @param {any} success
     * @param {any} error
     */
     this.SetMenus = function (menuId, subsystemIds, success, error) {
        var url = ServiceBaseAddress + '/api/Menu/'+menuId+'/Model';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, subsystemIds,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Menu };
